// filename: src/App.vue
<template>
  <div
      id="app"
      :class="{'app-layout': true, 'profile-layout': isProfileRoute, 'default-layout': !isProfileRoute}"
  >
    <SessionTimeoutDialog />

    <Header />

    <main class="main">
      <router-view/>
    </main>

    <Footer v-if="!isProfileRoute" />
  </div>
</template>

<script>
import { useRoute } from "vue-router";
import { computed, onMounted } from "vue";
import Header from "@/components/Layout/Header.vue";
import Footer from "@/components/Layout/Footer.vue";
import SessionTimeoutDialog from "@/components/Ui/SessionTimeoutDialog.vue";
import { useAuthStore } from "@/stores/auth";

export default {
  name: 'App',
  components: { Header, Footer, SessionTimeoutDialog },
  setup() {
    const route = useRoute();
    const isProfileRoute = computed(() => route.path.includes('profil'));

    onMounted(() => {
      if (route.path.includes('/profil/')) {
        document.documentElement.style.overflow = 'hidden';
      } else {
        document.documentElement.style.overflow = 'auto';
      }
    });

    return {
      isProfileRoute,
    };
  },
  computed: {
    authStore() {
      return useAuthStore();
    },
  },
};
</script>

<style lang="scss">
/* Global styles */
@use '@/styles/global-styles-index';

.app-layout {
  display: grid;
  min-height: 100vh;
}

.default-layout {
  grid-template-rows: 64px 1fr auto;

  @media (min-width: 768px) {
    grid-template-rows: 64px auto 80px;
  }
}

.profile-layout {
  grid-template-rows: 64px auto;

  @media (min-width: 768px) {
    grid-template-rows: 64px auto;
  }
}
</style>
