<template>
  <div class="access-code-form-wrapper">
    <img src="@/assets/images/logo-sign.svg" class="logo-sign" alt="Logo Sign" />

    <h1 class="step-title">Ten profil jest prywatny</h1>

    <p class="access-code-message">Podaj kod PIN, aby go wyświetlić</p>

    <form @submit.prevent="validateAccessCode" class="access-code-form">
      <v-otp-input
        v-model="accessCode"
        autofocus
        variant="solo-filled"
        length="5"
        :rules="[v => !!v || 'Access code is required', v => /^\d{5}$/.test(v) || 'Kod musi składać się z 5 cyfr']"
      />

      <button
        class="access-code-submit-button"
        :disabled="!accessCode || accessCode.length < 5"
        type="submit"
      >
        <span>Przejdź do profilu</span>
        <v-icon>mdi-arrow-right</v-icon>
      </button>

      <v-container class="error-messages">
        <p v-if="errorMessage">{{ errorMessage }}</p>
      </v-container>
    </form>
  </div>
</template>

<script>
import axiosInstance from "@/interceptors/axios.config";

export default {
  props: {
    slotNumber: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      accessCode: '',
      errorMessage: '',
    };
  },
  watch: {
    accessCode() {
      this.errorMessage = '';
    }
  },
  methods: {
    async validateAccessCode() {
      try {
        const formData = new FormData();
        formData.append('slot_number', this.slotNumber);
        formData.append('access_code', this.accessCode);

        const response = await axiosInstance.post('/validate-access-code/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          requiresReCaptcha: true,
          reCaptchaAction: 'validate_access_code',
        });

        if (response.data.is_access_code_valid) {
          // Store the access code verification status in local storage
          localStorage.setItem(`access_code_verified_${this.slotNumber}`, 'true');
          await this.$router.push({ name: 'memorial-profile-view', params: { slotNumber: this.slotNumber } });
        } else {
          this.errorMessage = 'Nieprawidłowy kod dostępu.';
        }
      } catch (error) {
        console.error('Error validating access code:', error);
        this.errorMessage = 'Wystąpił błąd. Spróbuj ponownie.';
      }
    },
  },
};
</script>

<style scoped>
.logo-sign {
  width: 64px;
  height: 64px;
  align-self: center;
  margin-bottom: 32px;
}

.access-code-form-wrapper {
  height: 100%;
  width: 100vw !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image:
    url('../assets/images/leaves-top-left.png'),
    url('../assets/images/leaves-bottom-left.png'),
    url('../assets/images/leaves-top-right.png'),
    url('../assets/images/leaves-bottom-right.png');
  background-position: top left, bottom left, top right, bottom right;
  background-repeat: no-repeat;
  background-size: 30%;

  @media (min-width: 768px) {
    background-size: auto;
  }
}

.access-code-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.error-messages {
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: center;
  text-align: center;
  color: #DC2626;
}

.access-code-message {
  font-family: "Source Serif 4", serif;
  font-size: 1.25rem;
  margin: 8px 0 16px;
}

.access-code-submit-button {
  height: 52px !important;
  width: fit-content !important;
  background-color: #075985 !important;
  color: #ffffff !important;
  box-shadow: none !important;
  border-radius: 16px !important;
  font-size: 0.875rem;
  padding: 0 24px !important;
  box-sizing: border-box;
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  @media (min-width: 480px) {
    width: fit-content !important;
  }

  &:disabled {
    background-color: #cccccc !important;
    color: #ffffff !important;
    cursor: not-allowed;
  }
}
</style>
